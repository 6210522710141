/**
 * @namespace
*/
var generic = generic || {};
var brx = brx || {};
site.rb = site.rb || {};
site.waitlist = site.waitlist || {};


/**
 * This file controls the different scenarios involved with waitlist.
 * An event, back in stock item or coming soon item are covered here.
 * An event is a single page with a simple form that notifies the user
 * when a particular event is live.
 * Back in stock notifies a user when an item is back in stock when their
 * email address has been submitted.
 * Coming soon is similar to back in stock except it notifies a user when
 * a product has become available.
 * If the user is signed in, their email will be prepopulated.
 * The functions below handle these different scenarios.
 * What is worth noting is this file should be included on an event page,
 * a spp page (for the product) or mpp page (for quickshop). 
 * @namespace
 * @methodOf brx
*/
site.waitlist.initEvent = function(args) {
  var formNode = args.formNode;
  var emailInput = args.emailInput;
  var formContainerNode = args.formContainerNode;
  var submitBtn = args.submitBtn;
  var loadingNode = args.loadingNode;
  
  if (!formNode[0] || 
    !emailInput[0] ||
    !formContainerNode[0] ||
    !submitBtn[0]) {
    return null;
  };
  
  // populate address if user is signed in
  var email = site.waitlist.getSignInEmail();
  emailInput.value = email;
  
  submitBtn.on('click', function(evt) {
    evt.preventDefault();
    var inputs = formNode.find('input');
    
    var formArgs = {};
    for ( var i = 0; i < inputs.length; i++ ) {
      var input  = inputs[i];
      var name = $(input).attr('name');
      if (name) {
        var val = input.value;
        formArgs[name] = String(val); 
      };
    };

    submitBtn.addClass('hidden');
    loadingNode.removeClass('hidden');

    site.waitlist.formSubmit({
      params: formArgs,
      callback: function(jsonRpcResponse) {
        generic.template.get({
          path: '/waitlist/includes/event_thank_you.tmpl',
          callback: function(html) {
            generic.showErrors([], site.waitlist.errorNode);
            site.waitlist.errorNode.removeClass('error_messages_display');
            formContainerNode.html(html);
          }
        });
      },
      errorCallBack: function(jsonRpcResponse) {
        var errorObjectsArray = jsonRpcResponse.getMessages();
        generic.showErrors(errorObjectsArray, site.waitlist.errorNode);
        submitBtn.removeClass('hidden');
        loadingNode.addClass('hidden');
      }
    });
    
  });
};


site.waitlist.initWaitlistButton = function(args) {
  if (!args.node || !args.skuData) {
    return null;
  };
  var waitlistNode = $('<a>');
  var obj = {
    skuId: args.skuData.SKU_BASE_ID    
  };
  if (site.productData.isTempOutOfStock(args.skuData)) {
    obj.msg = site.rb.language.tempOutOfStock;
    obj.requestType = 'BIS';
  } else if (site.productData.isComingSoon(args.skuData)) {
    obj.msg = site.rb.language.comingSoon;
    obj.requestType = 'CS';
  }
  waitlistNode.text(obj.msg);
  waitlistNode.on('click', function(evt) {
    evt.preventDefault();
    site.waitlist.showOverlay({
      params: obj
    });
  });
  return waitlistNode;
};


site.waitlist.showOverlay = function(args) {
  if (!args.params) {
    return null;
  }
  
  var waitlistContainer = $('div.waitlist');
  generic.template.get({
    path: '/waitlist/includes/waitlist_overlay.tmpl',
    proxEle : proxEle,
    callback: function(html) {
      generic.overlay.launch({
        content: html,
        cssStyle: {
          width: "320px"
        },
        includeBackground: true
      });
      var overlayContainer = $('#waitlist-overlay');
      if (!overlayContainer[0]) {
        return null;
      }
      site.waitlist.initForm({
        container: overlayContainer,
        params: args.params
      });
    }
  });

};


site.waitlist.initForm = function(args) {
  if (!args.params || !args.container[0]) {
    return null;
  };
  var container = args.container;
  var requestTypeInput = container.find('.request-type');
  var skuIdInput = container.find('.sku-base-id');
  var emailInput = container.find('.email-input');
  var submitBtn = container.find('.submit');
  var formNode = container.find('form');
  var loadingNode = container.find('.loading');
  var errorNode = container.find('.error_messages');
  if (!requestTypeInput[0] ||
    !skuIdInput[0] ||
    !submitBtn[0] ||
    !formNode[0] ||
    !emailInput[0] ) {
    return null;   
  };     
  // populate address if user is signed in
  var email = site.waitlist.getSignInEmail();
  emailInput.val(email);
  // set request type
  if (args.params.requestType) {
    requestTypeInput.val(args.params.requestType);  
  }
  // set base sku id
  if (args.params.skuId) {
    skuIdInput.val(args.params.skuId);    
  }
  // init form submit
  submitBtn.on('click', function(evt) {
    evt.preventDefault();
    var inputs = formNode.find('input');

    var formArgs = {};
    for ( var i = 0; i < inputs.length; i++ ) {
      var input   = inputs[i];
      var name  = $(input).attr('name');
      if (name) {
        var val    = input.value;
        formArgs[name] = val; 
      };
    };

    submitBtn.addClass('hidden');
    loadingNode.removeClass('hidden');

    site.waitlist.formSubmit({
      params: formArgs,
      callback: function(jsonRpcResponse) {
        generic.template.get({
          path: '/waitlist/includes/overlay_thank_you.tmpl',
          callback: function(html) {
            generic.showErrors([], errorNode);
            errorNode.removeClass('error_messages_display');
            container.html(html);
          }
        });
      },
      errorCallBack: function(jsonRpcResponse) {
        var errorObjectsArray = jsonRpcResponse.getMessages();
        generic.showErrors(errorObjectsArray, errorNode);
        submitBtn.removeClass('hidden');
        loadingNode.addClass('hidden');
      }
    });
  });  
};


site.waitlist.formSubmit = function(args) {
  if (!args || !args.callback) {
    return null;
  };

  generic.jsonrpc.fetch({
    method: 'form.rpc',
    params: [args.params],
    onSuccess: function(jsonRpcResponse) {
      args.callback(jsonRpcResponse);
    },
    onFailure: function (jsonRpcResponse) {
      args.errorCallBack(jsonRpcResponse);
    }
  });
};


site.waitlist.getSignInEmail = function() {
  var userPageData = page_data.globalnav && page_data.globalnav.user && page_data.globalnav.user.rpcdata ? page_data.globalnav.user.rpcdata : null;
  if (!userPageData) {
    return null;
  };
  var email = '';
  if (userPageData.signed_in) {
    email = userPageData.pc_email_address;
  };
  return email;
}

$(document).ready(function(e) {
  site.rb.getRBKeys();
});

site.rb.getRBKeys = function() {
  site.rb.language = generic.rb('language');
  site.rb.language.tempOutOfStock = site.rb.language.get('temp_out_of_stock');//2
  site.rb.language.comingSoon = site.rb.language.get('coming_soon'); //3
}
